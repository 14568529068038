type TConfig = {
  common: string[];
  feature: string;
};

export const socialLinksTranslationConfig: TConfig = {
  common: ['CommonUI.Controls'],
  feature: 'Feature.PromotedChannels'
};

export default {
  socialLinksTranslationConfig
};
