import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { authenticatedUser } from 'header-scripts';
import { eventStreamService } from 'core-roblox-utilities';
import { Link } from 'react-style-guide';
import { withTranslations, TranslateFunction } from 'react-utilities';
import socialLinksService from '../services/socialLinksService';
import { TGetGameSocialLinksResponse, TSocialLinkJumbotronType } from '../types/socialLinksType';
import socialLinksConstants from '../constants/socialLinksConstants';
import eventStreamConstants from '../constants/eventStreamConstants';
import { socialLinksTranslationConfig } from '../translation.config';

const { socialLinksTranslationMap, iconMap } = socialLinksConstants;
export const SocialLinksJumbotron = ({
  type,
  targetId,
  translate
}: {
  type: TSocialLinkJumbotronType;
  targetId: string;
  translate: TranslateFunction;
}): JSX.Element | null => {
  const [socialLinks, setSocialLinks] = useState<TGetGameSocialLinksResponse[] | undefined>(
    undefined
  );
  const onLinkClick = (link: TGetGameSocialLinksResponse) => {
    eventStreamService.sendEvent(
      ...((eventStreamConstants.socialLinkClickEvent(link) as unknown) as Parameters<
        typeof eventStreamService.sendEvent
      >)
    );
    window.open(link.url, '_blank');
  };

  useEffect(() => {
    const fetchSocialLinks = async () => {
      if (!authenticatedUser.isUnder13 && authenticatedUser.isAuthenticated) {
        try {
          const socialLinksData = await socialLinksService.getGameSocialLinks(targetId);
          setSocialLinks(socialLinksData);
        } catch (e) {
          // TODO (4/23/21, SOC-5018): To handle error when data does not return
          setSocialLinks([]);
        }
      } else {
        setSocialLinks([]);
      }
    };
    // eslint-disable-next-line no-void
    void fetchSocialLinks();
  }, []);

  if (!socialLinks || socialLinks?.length === 0) {
    return null;
  }

  return (
    <div>
      <h2>{translate(socialLinksTranslationMap.sectionHeader)}</h2>
      <ul className='game-social-links'>
        {socialLinks.map(link => (
          <Link
            onClick={() => {
              onLinkClick(link);
            }}
            onKeyDown={() => {
              onLinkClick(link);
            }}
            key={link.id}
            className='btn-secondary-lg border'>
            <span className={classNames('social-icon', iconMap[link.type])} />
            <div>{link.title}</div>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default Object.assign(
  withTranslations<{ type: TSocialLinkJumbotronType; targetId: string }>(
    SocialLinksJumbotron,
    socialLinksTranslationConfig
  ),
  {
    SocialLinkJumbotronType: TSocialLinkJumbotronType
  }
);
