import { eventStreamService } from 'core-roblox-utilities';
import { TGetGameSocialLinksResponse } from '../types/socialLinksType';

const { eventTypes } = eventStreamService;

export default {
  socialLinkClickEvent: (
    item: TGetGameSocialLinksResponse
  ): [Record<string, string>, Record<string, undefined | string | number>] => [
    {
      name: 'socialLinkClickEvent',
      type: 'socialLinkClickEvent',
      context: eventTypes.formInteraction
    },
    {
      assignmentId: item.id,
      assignmentType: item.type,
      socialLinkType: 'game',
      socialLinkUrl: item.url,
      socialLinkDisplayType: item.type
    }
  ]
};
